/* eslint-disable react-hooks/exhaustive-deps */
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/scrollbar'
import 'swiper/css/pagination'
import cn from 'classnames'
import styles from './ProductSwiper.module.css'

import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Navigation, Thumbs, Scrollbar, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Picture } from '@components/ui'
import { useUI } from '@components/ui/context'
import array from 'lodash/array'
import FancyBox from '../../common/FancyBox'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import ProductModelDisplay from '../ProductModelDisplay'
import { openEventCollect, closeEventCollect } from '../ProductModelDisplay/gtm'

export default function ProductSwiper({
  className = '',
  subClassName = '',
  preRender = {},
  variant,
  sliderImages,
  combinationImg,
  discount,
  discountText,
  imagesDirection = 'horizontal',
  showScrollbar = false,
  ga3Event,
  ga4Event,
  modelDisplayData,
  modelRender: ModelRender = ProductModelDisplay,
  s: gs = {},
  hideCoupon,
  showPagination = false,
}) {
  const s = { ...styles, ...gs }
  const { productSwiperIndex, setSwiperIndex } = useUI()
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [isMobile, setIsMobile] = useState(true)
  const [realIndex, setRealIndex] = useState(0)
  const [swiperRef, setSwiperRef] = useState(null)
  const [showModel, setShowModel] = useState(false)
  const [modelInited, setModelInited] = useState(false)

  const isVertical = imagesDirection === 'vertical'
  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth < 768) return setIsMobile(true)
    setIsMobile(false)
  }

  useEffect(() => {
    if (combinationImg && combinationImg?.length > 1) {
    } else if (
      variant?.metafields?.images == null ||
      variant?.metafields?.images?.length <= 0
    ) {
      const curIndex = array.findIndex(sliderImages, function (image) {
        return image?.url == variant?.image?.url
      })
      swiperRef?.slideTo(curIndex)
    }
  }, [variant?.id, combinationImg, swiperRef])

  useEffect(() => {
    swiperRef?.slideTo(productSwiperIndex)
  }, [productSwiperIndex])

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  useEffect(() => {
    if (showModel) {
      setModelInited(true)
    }
  }, [showModel])

  let imageList = []
  if (preRender?.sliderAutoPull) {
    imageList = sliderImages
  } else {
    const len = Math.ceil(15 / sliderImages?.length)
    for (let i = 0; i < len; i++) {
      sliderImages?.forEach((old) => {
        const image = { ...old }
        image.fancyBox = `list-${i}`
        imageList.push(image)
      })
    }
  }

  // GA4 大图点击放大后 埋点
  const handleSwiperClick = useCallback(
    (image, index) => {
      pageGTMEvent({
        event: 'window_map_display',
        page_group: `Product Detail Page_${variant.sku}`,
        image: `${Number(index) + 1}_${image.url}`,
      })
    },
    [variant]
  )

  //  ga4埋点
  const pushCarouselSwitchLayer = useCallback((slide) => {
    pageGTMEvent({
      event: 'small_carousel_switch',
      page_group: 'Product Detail Page_' + variant.sku,
      image: Number(slide.index) + 1 + '_' + slide.src,
    })
    ga3Event && pageGTMEvent(ga3Event)
    ga4Event &&
      pageGTMEvent({
        ...ga4Event,
        image: Number(slide.index) + 1 + '_' + slide.src,
      })
  }, [])

  // 产品缩略图点击
  const handleCarouselSlideClick = useCallback((slide) => {
    pushCarouselSwitchLayer(slide)
  }, [])

  // 显示3D模型
  const openModel = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      setShowModel(true)

      openEventCollect({ sku: variant.sku })
    },
    [variant.sku]
  )

  const closeModel = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      setShowModel(false)

      closeEventCollect({ sku: variant.sku })
    },
    [variant.sku]
  )

  const swiperConfig = useMemo(() => {
    if ((isVertical && isMobile) || showScrollbar) {
      return {
        modules: [Navigation, Thumbs, Scrollbar, Pagination],
        scrollbar: {
          hide: true,
        },
      }
    }

    return {
      modules: [Navigation, Thumbs, Pagination],
    }
  }, [isVertical, isMobile])

  const swiper2Config = useMemo(() => {
    return {
      ...(preRender?.sliderAutoPull && {}),
      loop: preRender?.sliderAutoPull ? false : true,
      spaceBetween: 10,
      slidesPerView: 5,
      direction: imagesDirection,
      freeMode: false,
      watchSlidesProgress: true,
      modules: [Navigation, Thumbs],
    }
  }, [isVertical, isMobile])
  let jsx = (
    <div className={s.productSwiperWrap}>
      <FancyBox>
        <Swiper
          onSwiper={setSwiperRef}
          loop={true}
          spaceBetween={10}
          {...swiperConfig}
          navigation={!isMobile}
          pagination={isMobile && showPagination ? {} : false}
          thumbs={{ swiper: thumbsSwiper }}
          className={cn(s.mySwiper, 'relative', className)}
          onSlideChange={(swiper) => setRealIndex(swiper.realIndex)}
        >
          {variant?.metafields?.infos?.gift_image && realIndex === 0 && (
            <div className={s.giftWrap}>
              <Picture
                alt={
                  variant?.metafields?.infos?.gift_image_alt ||
                  'This is Product Image'
                }
                loading={preRender?.lazySwiper ? 'lazy' : 'eager'}
                className={
                  'flex h-full w-auto items-center justify-center md:w-full'
                }
                source={`${variant?.metafields?.infos?.gift_image}`}
              />
            </div>
          )}
          {imageList?.map((image, i) => (
            <SwiperSlide
              key={image.url + i}
              virtualIndex={i}
              onClick={() => handleSwiperClick(image, i)}
            >
              <div className={s.imageContainer}>
                <a
                  data-fancybox={image.fancyBox || 'singleBox'}
                  href={image?.url}
                  className="relative h-full md:w-full"
                >
                  {discount?.price &&
                    !hideCoupon &&
                    variant?.availableForSale &&
                    image?.url?.split('?')?.[0] ===
                      variant?.image?.url?.split('?')?.[0] &&
                    (variant?.metafields?.freeGift
                      ? variant?.metafields?.freeGift?.showDiscounts
                      : true) && (
                      <div className="absolute left-4 top-0 h-[60px] w-[60px] text-base text-white">
                        {discountText?.saleBg &&
                          !variant?.metafields?.infos?.hideDiscountPrice && (
                            <Picture
                              alt="sales"
                              className="object-fill"
                              imgClassName="object-fill"
                              source={discountText?.saleBg}
                              layout="fill"
                            />
                          )}
                        <div className={s.discountText}>
                          <h3>{discount?.price}</h3>
                          <span>{discountText?.off}</span>
                        </div>
                      </div>
                    )}
                  <Picture
                    alt={
                      image?.alt || image?.altText || 'This is Product Image'
                    }
                    loading={
                      i === 0 && !preRender?.lazySwiper ? 'eager' : 'lazy'
                    }
                    className={
                      'flex h-full w-auto items-center justify-center md:w-full'
                    }
                    imgClassName={s.img}
                    source={`${image?.url}`}
                  />
                </a>
                {i === 0 && modelDisplayData && (
                  <div
                    className="absolute bottom-[20px] right-[26px] z-10 h-[56px] w-[56px] cursor-pointer overflow-hidden"
                    onClick={openModel}
                  >
                    <Picture
                      className="w-full"
                      source="https://cdn.shopify.com/s/files/1/0517/6767/3016/files/3d-icon-2.png?v=1673493633"
                    ></Picture>
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </FancyBox>
      <Swiper
        {...swiper2Config}
        onSwiper={setThumbsSwiper}
        className={cn(
          { [s.mySwiper2]: !isVertical },
          { [s.verticalSwiper2]: isVertical },
          subClassName,
          'swiper2'
        )}
        onClick={(e) => {
          if (!preRender?.sliderAutoPull) return null
          let index = e.clickedIndex
          if (index > 0 && index < e.slidesGrid.length) {
            const curIndex = index > e.activeIndex ? index : e.clickedIndex - 1
            e.slideTo(curIndex)
          }
        }}
      >
        {imageList?.map((image, i) => (
          <SwiperSlide
            key={i}
            virtualIndex={i}
            onClick={(e) => {
              handleCarouselSlideClick({ index: i, src: image?.url })
            }}
          >
            <div className={s.imageContainer}>
              <Picture
                alt={image?.alt || image?.altText || 'This is Product Image'}
                loading={
                  i <= 4 && !preRender?.lazyThumbsSwiper ? 'eager' : 'lazy'
                }
                className={'h-full'}
                imgClassName={s.img}
                source={`${image?.url}`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="absolute left-0 top-0 z-[1] h-full w-full bg-white"
        style={{ display: showModel ? 'block' : 'none' }}
      >
        <div className="absolute right-0 top-0 z-20 h-[40px] w-[40px] cursor-pointer md:right-[20px] md:top-[20px]">
          <Picture
            className="h-full w-full"
            source="https://cdn.shopify.com/s/files/1/0517/6767/3016/files/close-icon.png?v=1673494144"
            onClick={closeModel}
          ></Picture>
        </div>
        {modelInited && (
          <ModelRender data={modelDisplayData} active={showModel}></ModelRender>
        )}
      </div>
      <style jsx global>
        {`
          .${s.productSwiperWrap} .swiper .swiper-wrapper,
          .${s.productSwiperWrap} .swiper2 .swiper-wrapper {
            transform: translate3d(-550px, 0px, 0px);
          }

          @media (max-width: 1200px) {
            .${s.productSwiperWrap} .swiper .swiper-wrapper,
            .${s.productSwiperWrap} .swiper2 .swiper-wrapper {
              transform: translate3d(calc(-100vw * 0.4584), 0px, 0px);
            }
          }
          @media (max-width: 768px) {
            .${s.productSwiperWrap} .swiper .swiper-wrapper,
            .${s.productSwiperWrap} .swiper2 .swiper-wrapper {
              transform: translate3d(calc(-100vw), 0px, 0px);
            }
          }
          .${s.productSwiperWrap} .swiper2 .swiper-slide {
            opacity: 0.4;
            cursor: pointer;
            transition: opacity 0.2s ease;
            max-width: 20%;
          }
          .${s.productSwiperWrap} .swiper2 .swiper-slide-thumb-active {
            opacity: 1;
          }
          .${s.productSwiperWrap} .swiper-vertical .swiper-slide {
            max-width: 100%;
            opacity: 1;
            box-sizing: border-box;
          }
          .${s.productSwiperWrap} .swiper-vertical .swiper-slide:after {
            position: absolute;
            height: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.3);
            clear: both;
            content: ' ';
            display: block;
            font-size: 0;
          }
          .${s.productSwiperWrap} .swiper-vertical .swiper-slide-thumb-active {
            border: 2px solid #000000;
          }
          .${s.productSwiperWrap}
            .swiper-vertical
            .swiper-slide-thumb-active:after {
            background: transparent;
          }
          .${s.productSwiperWrap} .swiper-button-prev,
          .${s.productSwiperWrap} .swiper-button-next {
            opacity: 0;
            color: #000;
            display: flex;
            height: 44px;
            width: 44px;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.2);
            transition: all 0.2s ease;
          }
          .${s.productSwiperWrap} .swiper-button-prev:after,
          .${s.productSwiperWrap} .swiper-button-next:after {
            font-size: 18px;
            color: #fff;
          }
          .${s.productSwiperWrap} .swiper-button-prev:hover,
          .${s.productSwiperWrap} .swiper-button-next:hover {
            background-color: rgba(0, 0, 0, 0.4);
            font-size: 20px;
          }
          .${s.productSwiperWrap} .swiper:hover .swiper-button-prev,
          .${s.productSwiperWrap} .swiper:hover .swiper-button-next,
          .${s.productSwiperWrap} .swiper .swiper-scrollbar {
            opacity: 1 !important;
          }
        `}
      </style>
    </div>
  )

  if (isVertical) {
    return jsx
  } else {
    return <div className={cn('relative w-full', s.horizontalClass)}>{jsx}</div>
  }
}
